.projects {
  overflow-x: hidden;
  position: relative;
  padding: 80px;
  background-image: linear-gradient(90deg, #8ec5fc 0%, #e0c3fc 100%);
}

.projects__switches {
  @include flex(row, center, center);
}

.switch {
  margin: 0 40px;
}

#landing-pages,
#emails {
  display: none;
}

.projects__container {
  margin: 45px auto 0;
  @include grid(3, 2, 0px);
  width: 60%;
  min-width: 340px;
  border-radius: 25px;
}

.test-block {
  position: relative;
  width: 300px;
  height: 300px;
  background: black;
  border-radius: 20px;
  justify-self: center;
  overflow: hidden;
  margin: 40px 0;
  box-shadow: 0 20px 50px black;
}

.email__img {
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.email2__img {
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
  object-position: -20px;
}

.email3__img {
  object-fit: contain;
  object-position: -28px;
}

.email4__img {
  object-fit: contain;
  object-position: -28px;
}

.email5__img {
  object-fit: contain;
}

.landing-one-img {
  object-fit: contain;
}

.landing-two-img {
  object-fit: contain;
  object-position: -20px;
}

.description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include flex(column, center, center);
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.877);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.description:hover {
  opacity: 1;
}

.description__heading {
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
  text-decoration: underline;
  letter-spacing: 1.5px;
}

.description__text {
  margin: 0;
  padding: 10px;
  text-align: center;
  color: white;
  letter-spacing: 1.2;
}

.projects__square1 {
  background-image: linear-gradient(315deg, #a239f788 30%, #f374ff 100%);
  position: absolute;
  bottom: 50px;
  left: 200px;
  width: 500px;
  height: 500px;
  border-radius: 25px;
  z-index: 0;
}

.projects__square2 {
  background-image: linear-gradient(49deg, #2929ff88 0%, #807eff5e 100%);
  position: absolute;
  top: 165px;
  right: 300px;
  width: 250px;
  height: 250px;
  border-radius: 25px;
  z-index: 0;
}

@media screen and (max-width: 1870px) {
  .projects__container {
    @include grid(2, 3);
  }
}

@media screen and (max-width: 1300px) {
  .projects__container {
    @include grid(1, 0);
  }
}

@media screen and (max-width: 600px) {
  .projects {
    padding: 40px 0;
  }

  .projects__switches {
    flex-direction: column;

    .btn {
      margin-bottom: 15px;
    }
  }

  .projects__square1 {
    bottom: 20px;
  }

  .projects__square2 {
    top: 240px;
  }
}

@media screen and (max-width: 400px) {
  .projects__container {
    min-width: 300px;
  }

  .test-block {
    width: 280px;
    height: 280px;
  }

  .description__heading {
    font-size: 2rem;
  }

  .description__text {
    font-size: 1.8rem;
  }
}
