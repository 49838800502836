.header{
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100;
    min-height: 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.308);
    @include flex(row, space-between, center);

    &__logo{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding-left: 20px;

        &:hover{
            p{
                color: $accent;
                text-shadow: 0 0 rgba(0, 0, 0, 0.281);
            }
        }
        
        img{
            width: 50px;
            border-radius: 50%;
            padding: 10px 0
        };

        p{
            margin-bottom: 0;
            margin-left: 10px;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.37);
            text-decoration: none;
            color: white;
            transition: all 0.2s ease;
        }
    }

    &__nav{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 20px;

        a{
            text-decoration: none;
            color: white;
            padding: 10px;
            height: 100%;
            transition: all 0.2s ease;

            &:hover{
                color: $accent;
                background-color: white;
            }

            &.active{
                color: $secondary;
                background: white;
                box-shadow: inset 0 0 6px 0px rgba(0, 0, 0, 0.116);
            }
        }
    }
}

@media screen and (max-width: 615px) {
    .header{
        display: flex;
        justify-content: center;
        align-items: center;

        &__logo{
            display: none;
        }
    }
}