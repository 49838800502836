@mixin flex($dir, $just, $align) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
}

@mixin grid($column, $row, $gap: 0px) {
  display: grid;
  grid-template-columns: repeat($column, 1fr);
  grid-template-rows: repeat($row, 1fr);
  gap: $gap;
}
