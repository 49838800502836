//variables

$primary: #3c91ff;
$secondary: #807eff;
$accent: #b255ff;

//testing

.test {
  width: 100vw;
  height: 100vh;
}

//typography

*,
*::after,
*::before {
  box-sizing: border-box;
}

::selection {
  background: var(--color-primary);
  color: white;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: var(--color-body);
  font-size: 2.4rem;
  line-height: 1.5;
  background-image: linear-gradient(90deg, #8ec5fc 0%, #e0c3fc 100%);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

h1,
h2,
h3 {
  color: var(--color-headings);
  margin-bottom: 0.7rem;
  line-height: 1.1;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }
  h1 {
    font-size: 8rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.4rem;
  }
}

h1 {
  font-size: 7rem;
  letter-spacing: 2px;
}

h2 {
  font-size: 4rem;
  letter-spacing: 1.5px;
}

h3 {
  font-size: 3rem;
  font-weight: 500;
}

p {
  margin-top: 0;
}

a {
  text-decoration: none;
}

/*Blocks*/

.glass {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(5px);
}

.block {
  padding: 3rem 0;
  overflow-y: hidden;
}

/*buttons*/

.btn-outline {
  border: 2px solid white;
  color: white;
  background: rgba(255, 255, 255, 0);
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.btn-outline:hover {
  color: black;
  background: white;
}

.btn-primary {
  border: 2px solid $primary;
  color: $primary;
  background: transparent;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.btn-primary:hover {
  color: white;
  background: $primary;
}

.btn-primary:focus {
  color: white;
  background: darken($primary, 15%);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1.05);
}

.btn-secondary {
  border: 2px solid $secondary;
  color: $secondary;
  background: transparent;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.btn-secondary:hover {
  color: white;
  background: $secondary;
}

.btn-secondary:focus {
  color: white;
  background: darken($secondary, 5%);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1.05);
}

.btn-accent {
  border: 2px solid $accent;
  color: $accent;
  background: transparent;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.btn-accent:hover {
  color: white;
  background: $accent;
}

.btn-accent:focus {
  color: white;
  background: darken($accent, 15%);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1.05);
}

.btn {
  display: block;
  margin: 20px 10px;
  outline: 0;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 15px;
  letter-spacing: 1px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .btn {
    font-size: 1.8rem;
  }
}

//flex

.flex-column {
  @include flex(column, center, center);
}

.flex-column-start {
  @include flex(column, center, flex-start);
}

.flex-column-end {
  @include flex(column, end, center);
}

.flex-column-just-start {
  @include flex(column, flex-start, flex-start);
}

.flex-column-between {
  @include flex(column, space-between, center);
}

.flex-row {
  @include flex(row, center, center);
  flex-wrap: wrap;
}

.flex-row-between {
  @include flex(row, space-between, center);
  flex-wrap: wrap;
}

.flex-row-around {
  @include flex(row, space-around, center);
  flex-wrap: wrap;
}

.flex-row-start {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
}

.flex-row-just-start {
  @include flex(row, flex-start, center);
  flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
}