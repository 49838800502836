.about {
  position: relative;
  background-image: linear-gradient(90deg, #8ec5fc 0%, #e0c3fc 100%);
  overflow: hidden;
  padding: 90px 20px;
  height: 110vh;
  @include flex(row, center, center);
}

.about__container {
  @include grid(2, 1, 200px);
  width: 70%;
  margin: 0 auto;
}

.about__text {
  padding: 30px;
  border-radius: 25px;
  color: rgb(97, 97, 97);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.466);
  height: 600px;

  h2 {
    font-size: 7rem;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  p {
    font-size: 1.8rem;
    letter-spacing: 0.5px;
  }
}

.about__logo-container {
  border-radius: 25px;
  @include grid(2, 3, 30px);
  padding: 20px 15px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.466);
  height: 600px;
}

.logo-img-sm {
  width: 120px;
  justify-self: center;
  align-self: center;
}

.logo-img-lg {
  @extend .logo-img-sm;
  width: 260px;
}

.about__circle1 {
  clip-path: circle(50% at 50% 50%);
  position: absolute;
  width: 400px;
  height: 400px;
  top: 500px;
  left: 100px;
  background-image: linear-gradient(49deg, #2929ff88 0%, #807eff5e 100%);
}

.about__circle2 {
  clip-path: circle(50% at 50% 50%);
  position: absolute;
  width: 400px;
  height: 400px;
  top: 40px;
  right: 100px;
  background-image: linear-gradient(315deg, #a239f788 30%, #f374ff 100%);
}

@media screen and (max-width: 1879px) {
  .about {
    height: auto;
  }

  .about__container {
    @include grid(1, 2, 50px);
  }

  .about__text,
  .about__logo-container {
    height: 400px;
  }


  .about__logo-container {
    @include grid(6, 1, 10px);
  }
}

@media screen and (max-width: 1440px) {
  .about__logo-container {
    @include grid(3, 2, 10px);
  }

  .about__text {
    h2 {
      font-size: 5rem;
    }

    p {
      font-size: 1.6rem;
    }
  }
}

@media screen and (max-width: 850px) {
  .about__container {
    width: 85%;
  }

  .about__text {
    padding: 20px 15px;
  }

  .logo-img-sm {
    width: 80px;
  }

  .logo-img-lg {
    width: 175px;
  }
}

@media screen and (max-width: 700px) {
  .about__text {
    height: auto;
    text-align: center;

    h2 {
      font-size: 4rem;
    }
  }
}

@media screen and (max-width: 575px) {
  .about__logo-container {
    @include grid(2, 3, 10px);
  }

  .logo-img-sm {
    width: 50px;
  }

  .logo-img-lg {
    width: 120px;
  }
}

@media screen and (max-width: 400px) {
  .about__text {
    h2 {
      font-size: 3.5rem;
    }
  }

  .logo-img-lg {
    width: 50px;
  }
}
