.hero {
  position: relative;
  min-height: 800px;
  overflow: hidden;
  width: 100%;
  @include flex(row, center, center);
  background-image: linear-gradient(90deg, #8ec5fc 0%, #e0c3fc 100%);

  &__img-mobile{
    display: none;
  }
}

.hero__container {
  position: relative;
  @include grid(2, 1, 20px);
  grid-template-columns: 40% 60%;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.425);
  width: 65%;
  border-radius: 25px;
  z-index: 2;
  min-width: 1150px;
}

.hero__block1 {
  text-align: left;
  padding: 15px 25px;
  color: white;

  h1 {
    font-weight: 300;
    font-size: 9rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.349);
  }

  h2 {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.397);
  }

  .btn {
    margin-top: 40px;
    margin-left: 0;
    width: 250px;
  }
}

.hero__block2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;

  .hero__grid-container{
    align-self: center;
    padding: 10px;
  }
}

.hero__img {
  padding: 0 55px;
  width: 100%;
  border-radius: 50%;
}

.hero__svg1 {
  padding: 0 55px;
  width: 100%;
  fill: $secondary;
}

.hero__svg2 {
  @extend .hero__svg1;
  fill: $accent;
}

.hero__svg3 {
  @extend .hero__svg1;
  fill: $primary;
}

.circle {
  clip-path: circle(50% at 50% 50%);
  width: 350px;
  height: 350px;
  position: absolute;
  top: 600px;
  left: 300px;
  z-index: 0;
  background-image: linear-gradient(49deg, #2929ff88 0%, #807eff5e 100%);
}

.circle2 {
  clip-path: circle(50% at 50% 50%);
  width: 200px;
  height: 200px;
  position: absolute;
  top: 100px;
  left: 360px;
  z-index: 0;
  background-image: linear-gradient(305deg, #298dff88 0%, #807eff5e 100%);
}

.square {
  width: 500px;
  height: 500px;
  background-image: linear-gradient(45deg, #a239f754 60%, #f374ffad 100%);
  z-index: 1;
  position: absolute;
  top: 30px;
  right: 200px;
  border-radius: 25px;
  backdrop-filter: blur(5px);
}

.polygon {
  clip-path: circle(50% at 50% 50%);
  width: 450px;
  height: 450px;
  background-image: linear-gradient(135deg, #a239f754 0%, #3c91ff 80%);
  z-index: 0;
  position: absolute;
  top: 500px;
  right: 200px;
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 1440px) {
  .hero__img-mobile{
    border-radius: 50%;
    max-height: 200px;
    display: block;
    margin-top: 20px;
  }

  .circle {
    left: 100px;
    top: 80px;
  }

  .polygon {
    top: 120px;
    right: 100px;
  }

  .circle2,
  .square {
    display: none;
  }

  .hero__container {
    @include flex(column, center, center);
    min-width:auto;

    .btn{
      width: 100%;
    }
  }

  .hero__block1 {
    text-align: center;

    h1 {
      font-size: 6rem;
      margin-top: 0;
    }

    h2 {
      font-size: 3rem;
    }
  }

  .hero__block2{
    display: none;
  }

  .hero__img {
    width: 150px;
  }

  .hero__svg1 {
    top: 45%;
    left: 25%;
    width: 65px;
  }

  .hero__svg2 {
    top: 0;
    left: 10%;
    width: 50px;
  }

  .hero__svg3 {
    left: 80%;
    top: 20%;
    width: 80px;
  }
}

@media screen and (max-width: 1151px) {
  .hero__container{
    width: 90%;
    min-width: none;

    .btn{
      width: 100%;
    }
  }

}

@media screen and (max-width: 1024px) {
  .hero__block1 {
    h1 {
      font-size: 4.5rem;
    }
  }

  .hero__container {
    height: 95%;
  }
}

@media screen and (max-width: 768px) {
  .circle {
    left: 100px;
    top: 60px;
  }

  .polygon {
    top: 140px;
    right: 100px;
  }

  .hero__container {
    @include flex(column, center, center);
    min-width: 300px;
    width: 95%;
    height: 50%;
  }

  .hero__block1 {
    height: 100%;
    padding: 5px;

    .btn {
      margin: 20px auto;
    }
  }

  .hero__block2 {
    display: none;
  }
}
